import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import HomeAccordion from "../components/HomeAccordion/HomeAccordion";
import StaticSection from "../components/StaticSection/StaticSection";
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));

const StaticTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title} customClass={PageData?.custom_css_classname ? PageData?.custom_css_classname : ""}>

            <div className="layout-padding-top">
                <BreadcrumbModule parentname={PageData.choose_menu[0]?.strapi_parent?.title} parent={PageData.choose_menu[0]?.strapi_parent?.slug} pagename={PageData.title} />

                {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                    return (
                        <> 
                        {module.strapi_component === "page-modules.static-content" && <StaticSection {...module} pagename={PageData.title} />}
                        {module.strapi_component === "page-modules.accordion" && (
                <HomeAccordion bg_color={"hash"} {...module} />
              )}
                           
                        </>
                    )
                })}

            </div>
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default StaticTemplate

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
                ...AccordionFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CONTENT {
                ...StaticSectionFragment
                
              }
        }
    }
  }
`