import React from "react";
import { Accordion, Container, Row, Col } from "react-bootstrap";
// import ContentModule from "../../modules/content-render";
import './assets/styles/_index.scss';
const {ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const HomeAccordion = (props) => {
    return (
        <div id="faq" className={`${props?.design} ${props?.bg_color} home-accordion-wrapper`}>
              <Container>
                <Row>
                    <Col md={12}>
                    {props.title && <h2>{props?.title}</h2>}
            <Accordion className="home-accordion">
                {props.add.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.title}</Accordion.Header>
                        <Accordion.Body>
                            {item.content &&
                            <div className="description-bk">
                            <ContentModule Content={item.content?.data?.content} /> </div>}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            </Col>
            </Row>
            </Container>
        </div>
    )
}

export default HomeAccordion